import styled from '@emotion/styled'
import { mixins } from 'theme';

export const CheckInstructionContainer = styled.div`
  width: 30px;
  height: 30px;
  border: solid #030;
  border-radius: 7px;
  cursor: pointer;
  @media (max-width: ${mixins.mobileWidth}px) {
    .total-earning {
      padding-left: 56px;
      span {
        font-size: 20px;
      }
    }
  }
`;